import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, take, tap } from 'rxjs';

import { ConfirmationSettings } from 'projects/shared-models/src/lib/confirmation';

import { SettingsConfirmationApiService } from '../settings-confirmation-api/settings-confirmation-api.service';
import { CCAuthService } from '@heidelberg/control-center-frontend-integration/auth';
import { ActivityState } from '@data-terminal/shared-models';
import { neitherNullNorUndefined } from '../../../../../utils/src/lib/helpers/neither-null-nor-undefined';
import { omitEntityValues } from '@data-terminal/utils';

export enum ConfirmationSettingType {
    MAKE_READY = 'Makeready',
    PRINT_PALLET_SHEET = 'PrintPalletSheet',
    WASTE_SHEETS_ON_SWITCH_TO_GOOD_PRODUCTION = 'WasteSheetsOnSwitchToGoodProduction',
}

export enum ActionView {
    OPERATION = 'OPERATION',
    OTHER_ACTIVITIES = 'OTHER_ACTIVITIES',
}

@Injectable({
    providedIn: 'root',
})
export class SettingsConfirmationService {
    readonly #settingsConfirmationApi = inject(SettingsConfirmationApiService);
    readonly #ccAuthService = inject(CCAuthService);

    private static readonly defaultConfirmationSettings: ConfirmationSettings = {
        productionRun: true,
        makeready: true,
        otherActivity: true,
        printPalletSheet: true,
        wasteSheetsForGoodProduction: true,
    };

    #confirmationSettings: ConfirmationSettings = SettingsConfirmationService.defaultConfirmationSettings;
    readonly #confirmationSettings$ = new BehaviorSubject<ConfirmationSettings>(this.#confirmationSettings);

    public fetchConfirmationSettings(): Observable<ConfirmationSettings | undefined> {
        return this.#settingsConfirmationApi
            .fetchConfirmationSettings(this.#ccAuthService.getCurrentUser()?.email || '')
            .pipe(
                take(1),
                tap((settings) => {
                    this.#confirmationSettings = settings ?? SettingsConfirmationService.defaultConfirmationSettings;
                    this.#confirmationSettings$.next(this.#confirmationSettings);
                })
            );
    }

    public getConfirmationSettings$(): Observable<ConfirmationSettings> {
        return this.#confirmationSettings$.asObservable();
    }

    public updateConfirmationSettings(body: ConfirmationSettings): void {
        this.#settingsConfirmationApi
            .saveConfirmationSettings(omitEntityValues(body))
            .pipe(take(1))
            .subscribe((settings) => {
                this.#confirmationSettings = settings ?? SettingsConfirmationService.defaultConfirmationSettings;
                this.#confirmationSettings$.next(this.#confirmationSettings);
            });
    }

    public static getDefaultConfirmationSettings(): ConfirmationSettings {
        return SettingsConfirmationService.defaultConfirmationSettings;
    }

    public updateConfirmationSetting(
        actName: ConfirmationSettingType,
        previousState: boolean,
        actionView?: ActionView,
        jobStatus?: ActivityState
    ): void {
        if (this.#isConfirmationSettingOfMakeReadyType(actName)) {
            this.#confirmationSettings.makeready = !previousState;
        } else if (this.#isConfirmationSettingOfPrintPalletSheetType(actName)) {
            this.#confirmationSettings.printPalletSheet = !previousState;
        } else if (this.#isConfirmationSettingForWasteSheets(actName)) {
            this.#confirmationSettings.wasteSheetsForGoodProduction = !previousState;
        } else if (this.#isConfirmationSettingForProductionRun(jobStatus, actionView)) {
            this.#confirmationSettings.productionRun = !previousState;
        } else if (this.#isConfirmationSettingForOtherActivities(actionView)) {
            this.#confirmationSettings.otherActivity = !previousState;
        }

        this.updateConfirmationSettings(this.#confirmationSettings);
    }

    public shouldShowConfirmationDialog(
        actName: ConfirmationSettingType,
        actionView?: ActionView,
        jobStatus?: ActivityState
    ): boolean {
        if (this.#isConfirmationSettingOfMakeReadyType(actName)) {
            return neitherNullNorUndefined(this.#confirmationSettings.makeready)
                ? !!this.#confirmationSettings.makeready
                : true;
        } else if (this.#isConfirmationSettingOfPrintPalletSheetType(actName)) {
            return neitherNullNorUndefined(this.#confirmationSettings.printPalletSheet)
                ? !!this.#confirmationSettings.printPalletSheet
                : true;
        } else if (this.#isConfirmationSettingForWasteSheets(actName)) {
            return neitherNullNorUndefined(this.#confirmationSettings.wasteSheetsForGoodProduction)
                ? !!this.#confirmationSettings.wasteSheetsForGoodProduction
                : true;
        } else if (this.#isConfirmationSettingForProductionRun(jobStatus, actionView)) {
            return neitherNullNorUndefined(this.#confirmationSettings.productionRun)
                ? !!this.#confirmationSettings.productionRun
                : true;
        } else if (this.#isConfirmationSettingForOtherActivities(actionView)) {
            return neitherNullNorUndefined(this.#confirmationSettings.otherActivity)
                ? !!this.#confirmationSettings.otherActivity
                : true;
        }
        return false;
    }

    #isConfirmationSettingOfMakeReadyType(actName: ConfirmationSettingType): boolean {
        return actName === ConfirmationSettingType.MAKE_READY;
    }

    #isConfirmationSettingOfPrintPalletSheetType(actName: ConfirmationSettingType): boolean {
        return actName === ConfirmationSettingType.PRINT_PALLET_SHEET;
    }

    #isConfirmationSettingForProductionRun(
        jobStatus: ActivityState | undefined,
        actionView: ActionView | undefined
    ): boolean {
        return jobStatus === ActivityState.IN_PROGRESS && actionView === ActionView.OPERATION;
    }

    #isConfirmationSettingForOtherActivities(actionView: ActionView | undefined): boolean {
        return actionView === ActionView.OTHER_ACTIVITIES;
    }

    #isConfirmationSettingForWasteSheets(actName: ConfirmationSettingType): boolean {
        return actName === ConfirmationSettingType.WASTE_SHEETS_ON_SWITCH_TO_GOOD_PRODUCTION;
    }
}
